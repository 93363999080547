"use client";
import Header from "@/components/Header";
import { useEffect, useState } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const StickyHeader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = () => {
    if (typeof window !== "undefined") {
      // Check the scroll position
      const currentScrollY = window.scrollY;

      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header
      } else {
        setIsVisible(true); // Show header
      }

      setLastScrollY(currentScrollY); // Update the last scroll position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed top-0 w-full bg-white shadow-md transition-transform duration-300 ease-in-out z-[999] ${
        !isVisible && "-translate-y-full"
      }`}
    >
      <section className=" bg-orange-500 text-base py-1 font-light">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-5 lg:grid-cols-5 gap-3 items-center  justify-center md:justify-end font-medium text-white divide-x-0 md:divide-x divide-white">
            <div className="md:col-span-3 lg:col-span-4 text-center md:text-right pr-2">
              Email:{" "}
              <a href="mailto:rajiv@ergosmind.com" className="text-white">
                rajiv@ergosmind.com
              </a>
            </div>
            <div className="col-span-1 md:col-span-2 lg:col-span-1">
              <div className="flex items-center justify-center md:justify-end space-x-2">
                <a
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2"
                  href="https://www.facebook.com/rajivchell"
                  target="blank"
                >
                  <FaFacebook size={20} />
                </a>
                <a
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2"
                  href="https://twitter.com/rajivchell?lang=en"
                  target="blank"
                >
                  <FaTwitter size={20} />
                </a>
                <a
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white p-2"
                  href="https://www.instagram.com/rajivchell/"
                  target="blank"
                >
                  <FaInstagram size={20} />
                </a>
                <a
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2"
                  href="https://www.linkedin.com/in/rajiv-chelladurai-a88b3122/?originalSubdomain=in"
                  target="blank"
                >
                  <FaLinkedin size={20} />
                </a>
                <a
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white  p-2"
                  href="https://www.youtube.com/channel/UC9FLVvZyajhjr383zqcX57w"
                  target="blank"
                >
                  <FaYoutube size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Header />
    </div>
  );
};

export default StickyHeader;
