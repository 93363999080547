import React from "react";
import Link from "next/link";

const BuyOurNewBook = () => {
  return (
    <div className="z-[99]">
      <Link
        href="https://www.amazon.in/dp/B0B15HK9KK/ref=cm_sw_r_wa_api_i_43W4VW888QMATNS9DT60"
        target="_blank"
      >
        <button className="flex border-[1px] bg-white border-orange-500  text-orange-500 px-2 md:px-4 py-2 rounded ">
          <div className="text-sm md:text-base">Buy Our New Book</div>
        </button>
      </Link>
    </div>
  );
};

export default BuyOurNewBook;
