"use client";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";
import BuyOurNewBook from "@/components/BuyOurNewBook";

const navigation = [
  { name: "Home", href: "/" },
  { name: "Videos", href: "/video" },
  {
    name: "Blog",
    href: "/blog",
  },
  { name: "About", href: "/about-us" },
  { name: "Coaching", href: "/coaching" },
  { name: "Testimonials", href: "/testimonials" },
  { name: "Downloads", href: "/downloads" },
  { name: "Contact", href: "/contact-us" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20 font-sans">
      <Disclosure as="nav" className="bg-white">
        {({ open, close }) => (
          <>
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16 md:h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start gap-5">
                  <div className="flex-shrink-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        alt="logo"
                        width={226}
                        height={68}
                        className="w-28 h-auto md:w-full md:h-full"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex space-x-6 justify-center">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="text-black text-lg px-3 py-2 rounded-md font-medium flex items-center hover:text-orange-400"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-56">
                                {item.submenu.map((subitem) => (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 hover:text-orange-400"
                                    onClick={() => handleLinkClick(close)}
                                  >
                                    {subitem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="text-black px-0 py-2 rounded-md text-lg font-medium hover:text-orange-400"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                  <div className=" self-center">
                    <BuyOurNewBook />
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-black px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem) => (
                              <Link
                                key={subitem.name}
                                href={subitem.href}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => handleLinkClick(close)}
                              >
                                {subitem.name}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-black px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
              <div className="px-4 py-3 border-t border-gray-200">
                <BuyOurNewBook />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
